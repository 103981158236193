<template>
  <base-section id="products-spec">
    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="6"
        >
          <news-card-products
            v-bind="news"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProductsSpec',

    components: {
      NewsCardProducts: () => import('@/components/news/CardProducts'),
    },

    data: () => ({
      articles: [
        {
          title: 'Nitrile Gloves',
          html: '',
          readMore: true,
          src: require('@/assets/product_3.jpg'),
          prominent: true,
        },
        {
          title: 'Natural Rubber Gloves',
          html: '',
          readMore: true,
          src: require('@/assets/latex.jpg'),
          prominent: true,
        },
      ],
    }),
  }
</script>
